section#npSummary-div{
    height: 100%;
    padding: 20px 20px 10px 30px;
   // background-color: #f2f2f2;

    .tile-content{
        border-bottom: solid 5px #bedbf8;
        text-align: left;
        background-color:#fff;
        box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.09);
    }
}
