.reprocess-modal {
    padding: 16px;

    .header {
        display: flex;
        justify-content: space-between;
    }

    .actions {
        display: flex;
        gap: 12px;
    }

    .overlay {
        display: flex;
        justify-content: center;
    }
}