@import "../../theme";

div#user-panel {
  $aside-wd: 25em;
  $aside-ht: 8.4em;
  $aside-ht2: 12.3em;
  $aside-ht3: 12.3em;

  right: 0;
  z-index: 1000;
  width: $aside-wd;
  position: absolute;
  padding-right: 10px;

  > button {
    @include base-button();
    width: $user-badge-ht-sm;
    height: $user-badge-ht-sm;

    background-image: url(../../assets/images/aad_enabled.png);
    background-size: $user-badge-ht-sm $user-badge-ht-sm;

    float: right;
    margin: 10px 0;
    text-transform: uppercase;
    font-size: 11px;
  }

  @media screen and (max-width: $r-stop-1) {
    margin-right: 10px;
  }

  aside {
    position: absolute;
    top: $header-ht - 14;
    width: $aside-wd;
    height: $aside-ht - 3em;
    color: $user-panel-txt-clr;
    text-align: left;
    float: right;
    z-index: 1001;

    &.hide {
      display: none;
      transition: 0.2s;
      opacity: 0;
    }

    &.show {
      transition: 0.3s;
      opacity: 1;
      top: $header-ht;
      height: $aside-ht;

      > div {
        transition: 0.4s;
        height: $aside-ht3;
      }
    }

    > div {
      width: $aside-wd;
      height: $aside-ht - 4em;
      background-color: $user-panel-bkg-clr;
      border: $user-panel-border;
      overflow: hidden;

      /* Badge */
      > div {
        height: $user-badge-ht-lg;
        margin-bottom: 20px;

        > div {
          display: inline-block;
          vertical-align: top;
          margin: 12px;

          /* User photo */
          &:first-of-type {
            height: $user-badge-ht-lg;
            width: $user-badge-ht-lg;
            background-image: url(../../assets/images/aad_enabled.png);
            background-size: $user-badge-ht-lg $user-badge-ht-lg;
            background-position: left center;

            margin-right: 10px;
          }

          /* Name and email */
          &:nth-of-type(2) {
            /* Name */
            > div:first-of-type {
              margin: 0 !important;
              padding-top: 2px !important;
              font-size: 15px;
              font-family: $font-family;
            }

            /* Email address */
            > div:nth-of-type(2) {
              font-size: 12px;
              font-family: $font-family;
              margin-top: 5px;
              width: $aside-wd - 6em;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      /* Menu */
      ul {
        @include base-list();

        clear: both;
        margin-top: 30px !important;

        li {
          font-size: 16px;
          margin: 0 !important;

          &:hover {
            background-color: darken($user-panel-bkg-clr, 10%);
          }

          p {
            margin: 0;
            padding: 0;
          }

          a {
            padding: 5px 10px 5px 10px !important;
            display: block;
            vertical-align: middle;
            text-decoration: none;
            color: #262626;
          }

          .link-button {
            font-size: 15px;
            background-color: transparent;
            border: none;
            cursor: pointer;
            text-decoration: none;
            color: #262626;
            display: block;
            margin: 0;
            padding: 5px 10px 5px 10px !important;
          }
        }
      }
    }

    &.dismiss {
      height: $aside-ht2 !important;

      > div {
        height: $aside-ht2;
      }
    }
  }
}
