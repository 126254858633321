.detailsSectionHeader{
    border-bottom-color:#f2f2f2;
    border-bottom-style: solid;
    padding-left: 15px;
}

.sectionDetails{
    padding: 10px 10px 10px 10px;
}

#searchSpinner{
    padding-top:30px;
    padding-bottom:30px;
}

#addressSpinner{
    padding-top:20px;
    padding-bottom:15px;
}

section#detailsSection{
    p{
        font-size: 12px;
        text-align: left;
    }
    
    .addressRegion{
        font-size: 13px;
    }
    span{
        font-size: 13px;
    }
    .ms-Grid-row:hover{
        cursor: pointer;
        background: #f5f5f5;

        #copyIcon{
            display:inline-block;
            color: black;
        }    
    }
        
    section#key-value-text{
        border-bottom: dotted 0.5px #ddd;
     }
}