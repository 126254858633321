section#np-page{
    .backToResult{
        padding: 0px 20px 8px 20px;
        background-color: #4b8fd2;
    }

    .back-link{
        color:#EEE;
        padding-right: 4px;
        padding-top: 2px;
    }
    
    .back-link:hover {
        color: #EEE;
        border: solid;
        border-color: #EEE;
        border-radius: .3em .3em;
      }
}