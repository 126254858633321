#searchSpinner {
  padding-top: 30px;
}

section#searchSummaryDiv {
  padding: 10px;
}

section#search-page {
  .progress-indicator {
    margin-top: -9px;
  }
  #shimmer {
    padding: 0px 0px 12px 0px;
  }
}

.setFraudBtn {
  float: right;
  padding-top: 4px;
}
