section#status{
  p{
    vertical-align: middle;
    display: inline-block; 
    padding-right: 10px;   
    i {
      vertical-align: middle;
      font-size: 25px;
      height: 25px;
      width: 25px;
    }
  }
  p.success {
    color: green;
  }
  p.error {
    color: red;
  }
  p.inprogress {
    color: blue;
  }
  p.closed{
    color: rgb(202, 67, 67);
  }
}