@media (min-width: 1921px){
    .mediumScreen {
    display: none!important;
    }
}

#admin-panel-button{
    padding-bottom: 20px;
}

#command-button{
    background-color: rgb(244, 244, 244);
}

.command-button{
    background-color: rgb(244, 244, 244);
}
