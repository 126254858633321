#search-form{
    padding: 12px 28px 12px 20px;
    background-color: #4b8fd2;
}

.inputfield{
    width: 100%;
    height: 2.5em;
    border: none;
    float: left;
    margin-top: 4px;
    padding-left: 10px;
}

#submitButton{
    padding-top: 50px;
}