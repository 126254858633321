@import url("https://static2.sharepointonline.com/files/fabric/office-ui-fabric-core/9.6.1/css/fabric.min.css");
@import "theme";

body, html
{
  font-family:          $font-family;
  font-size:            $default-font-size;
  margin:               0;
  padding:              0;
  color:                $common-txt-clr;
}

h1, h2, h3, h4, h5
{
  margin:               0;
  padding:              0;
  font-family:          $font-family;
  font-weight:          normal;
}

p, li, div, span, label, td, a
{
  font-family:          $font-family;
  font-size:            $default-font-size;
}

a
{
  text-decoration:      none;
  cursor:               pointer;

  &:hover
  {
    color:              dodgerblue;
  }
}

.hidden
{
  display:              none;
}

.boldText{
  font-weight: bold;
  color: #333;
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;

  .tile-address {
      flex-basis: 20%;
      text-align: left;
  }
  .tile-content{
      border-bottom: solid 5px #bedbf8;
      text-align: left;
      background-color:#fff;
      box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
      height: fit-content;
  }
  .tile-40 {
      flex-basis: 38%;
      text-align: left;
      background-color:#fff;
      box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
      height: fit-content;
  }
  .tile-20 {
      flex-basis: 22%;
      text-align: left;
      background-color:#fff;
      box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
      height: fit-content;
  }
  .tile-25 {
      flex-basis: 25%;
      text-align: left;
      background-color:#fff;
      box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
      height: fit-content;
  }
  .tile-1{
      flex-basis: 1%;
      background-color: #f2f2f2;
  }
  .tile-60 {
      flex-basis: 74%;
      text-align: left;
      box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
      background-color:#fff;
      height: fit-content;
  }
}

main
{
  clear: both;
  padding-bottom:        50px;

}
