@import "../../theme";

#main > header {

  background-image: url(../../assets/images/MSFT_logo_rgb_C-Wht.png);
  background-repeat: no-repeat;
  background-position: center left 20px;
  background-size: 108px 47px;
  margin: 0;
  height: $header-ht;
  vertical-align: middle;
  position: relative;
  background-color: #393838;

  > h1 {
    float: left;
    margin: 17px 0 0 140px;
    color: #262626;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    border-left: solid 2px #555;
    padding-left: 16px;
    padding-right: 16px;

    > a {
      color:  #fff;
    }
  }

  > a {
    float: right;
    font-size: $nav-menu-font-size;
    margin-top: 22px;
  }

  @media screen and (max-width: $r-stop-1) {
    background-position: center center;
    border-bottom: solid 1px #e6e6e6;
    margin: 0 !important;

    > h1 {
      display: none;
    }
  }
  .navigation-item-menu {
    color:  #fff;
    height: 100%;
    font-size: 16px;
    font-weight: 600;
    float: left;
  }
}
