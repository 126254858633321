#success-region {
  .success {
    color: green;
  }
}

#failure-region {
  .failure {
    color: red;
  }
}
