section#details-list-grid{
    #copyIcon{
        display:none;
    }
    .ms-DetailsHeader{
        background-color: #eff6fc;
        margin-top:-15px;
    }
    .value-content:hover{
        #copyIcon{
            display:inline-block;
            color: black;
            font-size: 13px !important;
            margin: 5px 0 -5px 0;
        } 
    }

    p{
        margin-block-start: 0em;
        margin-block-end: 0em;
        font-size: 13px !important;
    }

    #status p i {
        vertical-align: middle;
        font-size: 18px !important;
        height: 25px;
        width: 25px;
    }

    section#key-value-text:hover{
        #copyIcon{
            font-size: 13px !important;
        }
    }
}