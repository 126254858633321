section#key-value-text{
    overflow-wrap: break-word;

    #copyIcon{
        display:none;
    }

    .key-value-tile-content:hover{
        #copyIcon{
            display:inline-block;
            color: black;
        }
    }

    .valueHyperLink{
        font-size: 12px;
    }

    #downloadIcon{
        padding-left: 5px;
    }
}

.link-button {
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0 !important;
}
