@import "../../theme";

section#error-page {
  h2 {
    padding: 0 0 15px 0;
  }
  .underlined {
    text-decoration: underline;
  }
}
