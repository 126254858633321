  
section#searchList-div{
        height: 100%;
        padding: 10px 10px 10px 10px;

       p{
        font-size: 12px;
        text-align: left;
    }
    span{
        font-size: 13px;
    }

        .tile-content{
            border-bottom: solid 5px #bedbf8;
            text-align: left;
            background-color:#fff;
            box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.14);
        }

        .detailsSectionHeader:hover{
            background: #f5f5f5;
        }

        #rowData{
            border-bottom: solid 0.5px #eaeaea;
        }
    }

    .sectionDetails{
        padding: 10px 10px 10px 10px;
    }