.fraudDialog {
  max-width: 100%;
  width: 600px;
  max-height: 100%;
  min-height: 430px;
  max-height: 460px;
}
.dialogContent .ms-Dialog-header {
  display: none;
}
