.outreachDialog {
    max-width: 100%;
    width : 600px;
    max-height: 100%;
    height : 310px;
}
.dialogContent .ms-Dialog-header{
display: none;
}
#outreachPopup
{
    width: 100%;
    height: 50px;
}