
$font-family-title:         'Segoe UI Light', SegoeUILight, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-title:           22pt;
$font-size-title-h2:        12pt;
$font-family:               'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$default-font-size:         15px;

$max-content-wd:            1200px;

$header-ht:                 56px;
$footer-ht:                 44px;

$common-txt-clr:            #262626;

$nav-menu-font-size:        14px;
$nav-menu-font-size-phone:  16px;
$nav-menu-font:             $font-family;

$nav-menu-bck-clr-phone:    #f6f6f6;
$nav-menu-border-phone:     solid 1px #e6e6e6;

$content-h3-font-size:      20px;
$content-h3-font-weight:    600;

$submenu-bck-clr:           #f4f4f4;
$submenu-hover-clr:         #eaeaea;
$submenu-border-clr:        $submenu-hover-clr;

$user-panel-bkg-clr:        $submenu-bck-clr;
$user-panel-txt-clr:        $common-txt-clr;
$user-panel-border:         solid 1px #c9c9c9;
$user-panel-wd:             330px;

$icon-font:                 "MWF-MDL2";

$r-stop-1:                  700px;

@font-face
{
  font-family:"MWF-MDL2";
  src:url("https://www.microsoft.com/mwf/_h/v2.98/mwf.app/fonts/mwfmdl2-v2.98.woff") format("woff"),
      url("https://www.microsoft.com/mwf/_h/v2.98/mwf.app/fonts/mwfmdl2-v2.98.ttf") format("truetype"),
      url("https://www.microsoft.com/mwf/_h/v2.98/mwf.app/fonts/mwfmdl2-v2.98.svg") format("svg")

}

$user-badge-ht-sm:         36px;
$user-badge-ht-lg:         64px;

@mixin user-photo($width)
{
  padding:                0;
  width:                  $width !important;
  height:                 $width !important;
  line-height:            $width;
  vertical-align:         middle;
  text-align:             center;
  border-radius:          $width / 2;
  background-color:       royalblue;
  color:                  white;
  font-size:              12px;
}


@mixin base-list()
{
  list-style:             none;
  margin:                 0;
  padding:                0;
}

@mixin base-button()
{
  border:                 none;
  background-color:       transparent;
  cursor:                 pointer;
}

@mixin close-button()
{
  @include base-button();
  
  float:              right;
  font-size:          20px;
  font-family:        'Arial Rounded MT', Arial, Helvetica, sans-serif !important;
  margin-top:         8px;
  padding-bottom:     3px;

  &:hover
  {
    background-color: white;
  }
}

@mixin fabric-button()
{
  @include base-button();
  
  background-color: #106ebe;
  color:            white;
  padding:          6px 20px 6px 20px;
  text-decoration:  none;
  text-align:       center;
  font-weight:      bold;
  width:            auto;
  display:          block;

  &:hover
  {
    background-color: darken(#106ebe, 5%);
  }
}
