section#documentDisplay{
    .documentRegion{
        display: none;
        box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.14);
        padding: 10px;
    }
    
    .ms-Grid-row:hover{
        cursor: pointer;
        background: #f5f5f5;

        #copyIcon{
            display:inline-block;
            color: black;
        }
    }
    border-bottom: solid 1px #ddd;

    p.docTitle{
        vertical-align: middle;
        display: inline-block;  
        i {
          vertical-align: middle;
          font-size: 18px;
        }
      }
}