@import url("https://static2.sharepointonline.com/files/fabric/office-ui-fabric-core/9.6.1/css/fabric.min.css");
@import "../../theme";


section#sign-in-page
{
  button
  {
    margin-top:    20px !important;
    margin-bottom: 20px !important;
  }
}
