@import "../../theme";

section#sign-in-cta {
  float: right;
  margin: 15px 0;
  .primary-button {
    margin-left: 20px;
    display: inline;
    padding-right: 10px;
    color: #fff;
    background-color: #393838;
    border-width: 0px;
    font-size: 15px;
  }

  @media screen and (max-width: $r-stop-1)
  {
    .cta-text {
      display: none;
    }
  }
}
